<template>
  <div class="text-input-wrapper">
    <InputLabel :id="id" :text="labelText" :mandatory="mandatory" />
    <InputText
      :disabled="disabled"
      :name="name"
      :id="id"
      v-model="value"
      :placeholder="placeholder"
      :processOn="processOn"
      :isValid="isValid"
    />
    <div class="validation-message-wrapper" v-if="shouldValidate">
      <InputValidationMessage :message="validationMessage" />
    </div>
  </div>
</template>

<script>
import { generateUID } from "../../utils/generateUID";

import InputLabel from "../elements/InputLabel";
import InputText from "../elements/InputText";
import InputValidationMessage from "../elements/InputValidationMessage";

export default {
  name: "TextInput",
  props: {
    name: String,
    labelText: String,
    mandatory: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    processOn: {
      type: String,
      default: "input",
    },
    shouldValidate: {
      type: Boolean,
      default: true,
    },
    validationMessage: String,
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      id: generateUID(),
      value: this.modelValue,
    };
  },
  computed: {
    isValid: function () {
      return !this.validationMessage;
    },
  },
  watch: {
    value: function () {
      this.$emit("update:modelValue", this.value);
    },
  },
  components: {
    InputLabel,
    InputText,
    InputValidationMessage,
  },
};
</script>

<style lang="scss" scoped>
.text-input-wrapper {
  width: 100%;
  position: relative;

  .validation-message-wrapper {
    min-height: 25px;
  }
}
</style>
