import urls from "./urls";
import tokens from "./tokens";

const register = async (data) => {
  const registrationResponce = await fetch(urls.register, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: tokens.formAPItoken,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response)
    .catch((error) => error);
  return registrationResponce;
};

const update = async (data) => {
  const updateResponce = await fetch(urls.update, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: tokens.formAPItoken,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response)
    .catch((error) => error);
  return updateResponce;
};

const sendfile = async (id, base64, name, type) => {
  const data = {
    id: id,
    files: [
      {
        base64: base64,
        name: name,
        type: type,
      },
    ],
  };
  const fileResponce = await fetch(urls.file, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: tokens.formAPItoken,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response)
    .catch((error) => error);
  return fileResponce;
};

const sendCV = async (id) => {
  const updateResponce = await fetch(urls.sendCV + id, {
    method: "GET",
    headers: {
      Authorization: tokens.formAPItoken,
    },
  })
    .then((response) => response)
    .catch((error) => error);
  return updateResponce;
};

export { register, update, sendfile, sendCV };
