const moduleEducation = {
  state: () => ({
    educationList: {},
    courses: {},
    languages: {},
  }),
  getters: {
    getEducationList(state) {
      return state.educationList;
    },
    getCourses(state) {
      return state.courses;
    },
    getLanguages(state) {
      return state.languages;
    },
  },
  mutations: {
    // Language-related mutations
    updateLanguage(state, payload) {
      const { id, language, level } = payload;

      state.languages[id] = {
        language: language,
        level: level,
      };
    },
    removeLanguage(state, id) {
      delete state.languages[id];
    },

    // Course-related mutations
    updateCourse(state, payload) {
      const { id, nameCourse, specialty, author, beginDate, endDate } = payload;

      state.courses[id] = {
        nameCourse: nameCourse,
        specialty: specialty,
        author: author,
        beginDate: beginDate,
        endDate: endDate,
      };
    },
    removeCourse(state, id) {
      delete state.courses[id];
    },

    // Education-related mutations
    updateEducation(state, payload) {
      const {
        id,
        institution,
        faculty,
        specialty,
        beginDate,
        endDate,
      } = payload;

      state.educationList[id] = {
        institution: institution,
        faculty: faculty,
        specialty: specialty,
        beginDate: beginDate,
        endDate: endDate,
      };
    },
    removeEducation(state, id) {
      delete state.educationList[id];
    },
  },
  actions: {
    // Language-related actions
    updateLanguage(context, payload) {
      context.commit("updateLanguage", payload);
    },
    removeLanguage(context, payload) {
      context.commit("removeLanguage", payload);
    },
    // Course-related actions
    updateCourse(context, payload) {
      context.commit("updateCourse", payload);
    },
    removeCourse(context, payload) {
      context.commit("removeCourse", payload);
    },
    // Education-related actions
    updateEducation(context, payload) {
      context.commit("updateEducation", payload);
    },
    removeEducation(context, payload) {
      context.commit("removeEducation", payload);
    },
  },
};

export { moduleEducation };
