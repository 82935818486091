<template>
  <PrimaryTemplate
    :step="step"
    :buttonFunction="submit"
    :needBackButton="false"
    :loading="loading"
    mainTitle="Personal Data"
  >
    <TextInput
      :disabled="loading"
      name="fname"
      labelText="First Name"
      :mandatory="true"
      placeholder="Your Name"
      v-model="firstName"
      :validationMessage="errorMessages.firstName"
    />
    <TextInput
      :disabled="loading"
      name="lname"
      labelText="Last Name"
      :mandatory="true"
      placeholder="Your Last Name"
      v-model="lastName"
      :validationMessage="errorMessages.lastName"
    />
    <TextInput
      :disabled="loading"
      name="email"
      labelText="E-mail"
      :mandatory="true"
      placeholder="E-mail"
      v-model="email"
      processOn="change"
      :validationMessage="errorMessages.email"
    />
    <div v-if="errorMessage" class="error-message red">
      {{ errorMessage }}
    </div>
  </PrimaryTemplate>
</template>

<script>
import PrimaryTemplate from "@/layouts/PrimaryTemplate.vue";
import TextInput from "../components/utility/TextInput";
import { register } from "../api/formdata";

import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  numeric,
  not,
  maxLength,
} from "@vuelidate/validators";
import { getErrorMessage } from "../utils/getErrorMessage.js";

export default {
  name: "Intro",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      step: "Intro",
      firstName: "",
      lastName: "",
      email: "",
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    errorMessages: function () {
      return {
        firstName: getErrorMessage(this.v$.firstName.$errors),
        lastName: getErrorMessage(this.v$.lastName.$errors),
        email: getErrorMessage(this.v$.email.$errors),
      };
    },
  },
  methods: {
    async submit() {
      this.v$.$touch();
      let modifyLoading = this.modifyLoading;
      let setError = this.setError;
      let router = this.$router;
      if (!this.v$.$invalid) {
        modifyLoading(true);
        setError(null);

        let thisData = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
        };

        await register(thisData).then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            response.json().then(function (r) {
              modifyLoading(false);
              let thisDataStringified = JSON.stringify({
                id: r.data.id,
                data: [thisData],
              });
              localStorage.setItem("data", thisDataStringified);
              router.push({ path: "/basic-information" });
            });
          } else {
            modifyLoading(false);
            if (response.status == 400) {
              setError(
                "Validation error! Please check the accuracy of the entered data!"
              );
            } else {
              setError("Form submission error! Please try again later!");
            }
            console.log("Error: ");
            console.log(response.status);
          }
        });
      }
    },
    modifyLoading(loading) {
      this.loading = loading;
    },
    setError(message) {
      this.errorMessage = message;
    },
  },
  components: {
    PrimaryTemplate,
    TextInput,
  },
  mounted() {
    localStorage.removeItem("data");
  },
  validations() {
    return {
      firstName: {
        required,
        isValid: not(numeric),
        maxLength: maxLength(50),
        $autoDirty: true,
      },
      lastName: {
        required,
        isValid: not(numeric),
        maxLength: maxLength(50),
        $autoDirty: true,
      },
      email: {
        required,
        email,
        $autoDirty: true,
      },
    };
  },
};
</script>
<style>
.red {
  color: red;
}
:disabled {
  opacity: 0.4;
}
</style>
