const moduleExperience = {
  state: () => ({
    workplaces: {},
    reason: "",
    infoSource: "",
    firstWork: false,
  }),
  getters: {
    getWorkplaces(state) {
      return state.workplaces;
    },
    // getProjects: (state) => (workplaceId) => {
    //   return state.workplaces.filter((el) => el.id === workplaceId)[0].projects;
    // },
    getReason(state) {
      return state.reason;
    },
    getInfoSource(state) {
      return state.infoSource;
    },
    getFirstWork(state) {
      return state.firstWork;
    },
  },
  mutations: {
    setFirstWork(state, value) {
      state.firstWork = value;
    },

    setReason(state, data) {
      state.reason = data;
    },
    setInfoSource(state, data) {
      state.infoSource = data;
    },

    updateWorkplace(state, payload) {
      const { id, name, position, startWorkingDate, endWorkingDate } = payload;

      state.workplaces[id] = {
        workplaceName: name,
        workplacePosition: position,
        startWorkingDate: startWorkingDate,
        endWorkingDate: endWorkingDate,
      };
    },

    removeWorkplace(state, id) {
      delete state.workplaces[id];
    },

    // addProject(state, payload) {
    //   let workplaceIndex = state.workplaces.findIndex(
    //     (el) => el.id === payload.workplaceId
    //   );
    //   state.workplaces[workplaceIndex].projects.push({
    //     id: generateUID(),
    //     ...payload.fields,
    //   });
    // },
    // removeProject(state, payload) {
    //   let workplaceIndex = state.workplaces.findIndex(
    //     (el) => el.id === payload.workplaceId
    //   );
    //   state.workplaces[workplaceIndex].projects = state.workplaces[
    //     workplaceIndex
    //   ].projects.filter((project) => project.id !== payload.projectId);
    // },
    // updateProjectOfWorkplaceById(state, payload) {
    //   let workplaceIndex = state.workplaces.findIndex(
    //     (el) => el.id === payload.workplaceId
    //   );
    //   let projectIndex = state.workplaces[workplaceIndex].projects.findIndex(
    //     (x) => x.id == payload.id
    //   );
    //   state.workplaces[workplaceIndex].projects[projectIndex] = {
    //     ...state.workplaces[workplaceIndex].projects[projectIndex],
    //     ...payload.fields,
    //   };
    // },
  },
  actions: {
    setFirstWork(context, payload) {
      context.commit("setFirstWork", payload);
    },
    // updateWorkplaceById(context, payload) {
    //   context.commit("updateWorkplaceById", payload);
    // },
    setReason(context, payload) {
      context.commit("setReason", payload);
    },
    setInfoSource(context, payload) {
      context.commit("setInfoSource", payload);
    },
    // addNewProject(context, payload, workplaceId) {
    //   context.commit("addProject", payload, workplaceId);
    // },
    // removeProject(context, id, workplaceId) {
    //   context.commit("removeProject", id, workplaceId);
    // },
    // updateProjectById(context, workplaceId, payload) {
    //   context.commit("updateProjectOfWorkplaceById", workplaceId, payload);
    // },
    updateWorkplace(context, payload) {
      context.commit("updateWorkplace", payload);
    },
    removeWorkplace(context, id) {
      context.commit("removeWorkplace", id);
    },
  },
};

export { moduleExperience };
