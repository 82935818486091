<template>
  <router-view />
</template>

<script>
import { getOpenVacancies } from "./api/vacancies";

export default {
  created() {
    // API Calls
    getOpenVacancies();
  },
};
</script>

<style lang="scss">
@import "./assets/scss/_build.scss";
</style>
