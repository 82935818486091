<template>
  <div class="main-template">
    <div class="content-wrapper">
      <div class="heading-wrapper">
        <div class="logo-wrapper">
          <img src="../assets/images/logo-azati.svg" alt="Azati Logo" />
        </div>
        <h1 class="main-heading">Job Application Form</h1>
      </div>
      <CardTemplate
        :step="step"
        :loading="loading"
        :buttonFunction="buttonFunction"
        :backFunction="backFunction"
        :needBackButton="needBackButton"
        :nextButtonText="nextButtonText"
      >
        <div class="main-title-wrapper">
          <h1 class="main-title">{{ mainTitle }}</h1>
          <slot name="toggleButton"></slot>
        </div>

        <!-- slot content start -->
        <slot></slot>
        <!-- slot content end -->
      </CardTemplate>
    </div>
  </div>
</template>

<script>
import CardTemplate from "@/components/serviceComponents/CardTemplate.vue";

export default {
  name: "PrimaryTemplate",
  props: {
    step: String,
    buttonFunction: Function,
    mainTitle: String,
    needBackButton: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    backFunction: Function,
    nextButtonText: {
      type: String,
      default: "Next",
    },
    // isShowToggle: {
    //   TODO: What the fuck is this?
    //   type: Boolean,
    //   default: false
    // }
  },
  // data() {
  //   return {
  //     TODO: What the fuck is this?
  //     firstWork: true
  //   };
  // },
  components: {
    CardTemplate,
  },
};
</script>
<style lang="scss">
.main-title-wrapper {
  .toggle-wrapper {
    width: 60% !important;
    @media (max-width: 375px) {
      width: 155% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.main-template {
  background-color: $template-color;
  min-height: 100vh;
  padding: 0 15px;
  position: relative;
}

.content-wrapper {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.heading-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  padding-top: 30px;
  padding-bottom: 20px;

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 25px;

    img {
      min-width: 180px;
    }
  }
}

.main-heading {
  color: $main-heading-color;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
}

.main-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  width: 100%;
  .main-title {
    color: $text-black;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    width: 100%;
  }
}

@media screen and (max-width: 689.88px) {
  .main-template {
    .content-wrapper {
      .heading-wrapper {
        padding: 10px 0px;
        .logo-wrapper {
          img {
            min-width: 120px;
          }
        }
        .main-heading {
          font-size: 30px;
          line-height: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 479.88px) {
  .main-template {
    .content-wrapper {
      .heading-wrapper {
        padding: 10px 0px;
        .logo-wrapper {
          margin-right: 15px;
          img {
            min-width: 100px;
          }
        }
        .main-heading {
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 369.88px) {
  .main-template {
    .content-wrapper {
      .heading-wrapper {
        .logo-wrapper {
          margin-right: 10px;
          img {
            min-width: 80px;
          }
        }
        .main-heading {
        }
      }
    }
  }
}
</style>
