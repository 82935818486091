<template>
  <div class="input-validation-message" v-show="!isValid">
    <span class="error-message">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "InputValidationMessage",
  props: {
    message: String,
  },
  computed: {
    isValid: function () {
      return !this.message;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.input-validation-message {
  position: relative;
  display: flex;

  .error-message {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: $mark-red;
    min-height: 20px;
    top: 0px;
  }
}
</style>
