import { createRouter, createWebHistory } from "vue-router";
import Intro from "../views/Intro.vue";

const routes = [
  {
    path: "/",
    name: "Intro",
    component: Intro,
    meta: {
      title: "Personal Data - Job Application Form",
    },
  },
  {
    path: "/basic-information",
    name: "BasicInfo",
    component: () => import("../views/BasicInfo.vue"),
    meta: {
      title: "Basic Information - Job Application Form",
    },
  },
  {
    path: "/expectations",
    name: "Expectations",
    component: () => import("../views/Expectations.vue"),
    meta: {
      title: "Expectations - Job Application Form",
    },
  },
  {
    path: "/education",
    name: "Education",
    component: () => import("../views/Education.vue"),
    meta: {
      title: "Education - Job Application Form",
    },
  },
  {
    path: "/experience",
    name: "Experience",
    component: () => import("../views/Experience.vue"),
    meta: {
      title: "Experience - Azati",
    },
  },
  {
    path: "/technology-stack",
    name: "TechnoStack",
    component: () => import("../views/TechnoStack.vue"),
    meta: {
      title: "Technology Stack - Azati",
    },
  },
  {
    path: "/extra-information",
    name: "ExtraInfo",
    component: () => import("../views/ExtraInfo.vue"),
    meta: {
      title: "Extra Info - Azati",
    },
  },
  {
    path: "/answers-saved",
    name: "AnswersSaved",
    component: () => import("../views/AnswersSaved.vue"),
    meta: {
      title: "Answers Saved - Azati",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Feature: Add meta tags with vue router
  // https://router.vuejs.org/guide/advanced/navigation-guards.html
  // https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
