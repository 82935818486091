import urls from "./urls";

const getOpenVacancies = async () => {
  const openPositions = await fetch(urls.openVacancies)
    .then((response) => response.json())
    .catch((error) => {
      console.warn(error);
      return {
        vacancies: [
          "Software Engineer",
          "Database Developer",
          "Mobile App Developer",
          "Bisiness Analyst",
          "Sales Manager",
          "QA Engineer",
          "Project Manager",
        ],
      };
    });

  sessionStorage.setItem("positions", JSON.stringify(openPositions.vacancies));
};

export { getOpenVacancies };
