//const baseDomain = "http://recruitment-form-back-end-master.host/";
const baseDomain = "https://azati.ai";
//const formDomain = "";
const formDomain = "";
export default {
  openVacancies: baseDomain + "/get_vacancy.php",
  register: formDomain + "/register-user",
  update: formDomain + "/update-user",
  file: formDomain + "/attach-files",
  sendCV: formDomain + "/send-cv/",
};
