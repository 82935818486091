<template>
  <div class="input-component">
    <input
      class="input"
      :class="{ 'with-error': !isValid }"
      :disabled="disabled"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :value="modelValue"
      @[processOn]="onInput"
      type="text"
    />
    <div class="error-icon" v-show="!isValid"></div>
  </div>
</template>

<script>
import { generateUID } from "../../utils/generateUID";

export default {
  name: "InputText",
  props: {
    id: {
      type: String,
      default: generateUID(),
    },
    placeholder: String,
    name: String,
    isValid: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    processOn: {
      type: String,
      default: "input",
    },
    modelValue: String,
  },
  emits: ["update:modelValue"],
  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.input-component {
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 100%;
  }

  input {
    max-width: 630px;
    width: 100%;
    height: 50px;
    border: 1px solid $main-grey;
    border-radius: 10px;
    padding: 0 20px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    box-sizing: border-box;

    transition: 0.3s ease;

    &::placeholder {
      color: $main-grey;
      font-weight: bold;
    }

    &:focus {
      border-color: $template-color;
    }

    &.with-error {
      padding-right: 45px;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .error-icon {
    background-image: url("../../assets/images/alert-icon.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 13px;
  }
}
</style>
