<template>
  <label :for="id" class="input-label">
    {{ text }}
    <span v-if="mandatory" class="mandatory-mark">*</span>
  </label>
</template>

<script>
export default {
  name: "InputLabel",
  props: {
    id: {
      type: String,
      default: "Default label id",
    },
    text: {
      type: String,
      default: "Default label text",
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.input-label {
  color: $text-grey;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  min-height: 20px;
  display: flex;

  span {
    margin-left: 3px;
  }

  .mandatory-mark {
    color: $mark-red;
  }
}
</style>
