const translateFunction = (message) => {
  let translatedText = "";
  if (message !== "") {
    switch (message) {
      case "Value is required":
        translatedText = "The value is required";
        break;
      case "Value is not a valid email address":
        translatedText = "Invalid email address";
        break;
      case "The value does not match the provided validator":
        translatedText = "Only letters are allowed";
        break;
      case "The value must be alpha-numeric":
        translatedText = "Only letters and numbers are allowed";
        break;
      case "The value is not a valid URL address":
        translatedText = "Invalid URL";
        break;
      case "The value must be between 1 and 31":
        translatedText = "The date should be within the range of 1 to 31";
        break;
      case "The minimum value allowed is 1900":
        translatedText = "The minimum value is 1900 ";
        break;
      case "The maximum value is 2030":
        translatedText = "The maximum value is 2030 ";
        break;
      case "This field should be at least 9 long.":
        translatedText = "A minimum of 9 characters is required";
        break;
      case "Value must be numeric":
        translatedText = "Only numbers are allowed";
        break;
      case "Date is not valid":
        translatedText = "Date selection error";
        break;
      case "Unable to add new language when the previous is not filled":
        translatedText = "Please fill in the current field before adding a new one";
        break;
      case "Only numbers and + are allowed":
        translatedText = "Only numbers and + are allowed";
        break;
      case "Digits are not allowed":
        translatedText = "Numbers are not allowed";
        break;
      default:
        translatedText = "Field filling error";
        console.log(message);
        break;
    }
  }
  return translatedText;
};

export { translateFunction };
